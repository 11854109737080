<template>
	<div class="downloadPage">
		<!-- <headerComponent /> -->

		<img
			:src="require('@/assets/bgc1.png')"
			alt=""
			class="banner"
		/>

		<div class="tab">
			<div
				class="tab_title"
				@click="tabIndex = index"
				v-for="(item, index) in tabList"
				:key="index"
			>
				<img
					:src="item.img"
					alt=""
				/>
				<span :class="tabIndex == index ? 'isActive' : ''">{{
          item.name
        }}</span>

				<img
					v-if="item.tuijian"
					class="recommend"
					:src="item.tuijian"
					alt=""
				/>
			</div>
		</div>

		<div
			class="item_content"
			v-if="tabIndex == 0"
		>
			<div class="left">
				<div class="title">
					<span>小程序</span>
					AI绘画
				</div>
				<div class="text">现已推出小程序版本，立即扫码体验</div>

				<div class="btnImg1">
					<div><img
							:src="require('assets/writtenAi/code1.jpg')"
							alt=""
						/>
						<div class="box">
							<img
								:src="require('assets/images/xiaocehngxuban.png')"
								alt=""
							/>
							<span>扫码即可使用</span>
						</div>
					</div>

				</div>
			</div>
			<div class="right">
				<img
					style="width:376.2px;"
					:src="require('assets/writtenAi/bgc1.png')"
					alt=""
				/>
			</div>
		</div>

		<div
			class="item_content"
			v-if="tabIndex == 1"
		>
			<div class="left">
				<div class="title">
					<span>小程序</span>
					挖潜智库
				</div>

				<div class="text">现已推出小程序版本，立即扫码体验</div>

				<div class="btnImg1">
					<div>
						<img
							:src="require('assets/writtenAi/code2.jpg')"
							alt=""
						/>
						<div class="box">
							<img
								:src="require('assets/images/xiaocehngxuban.png')"
								alt=""
							/>
							<span>扫码即可使用</span>
						</div>

					</div>
					<!-- <div>
						<img
							:src="require('assets/images/download-guide/ios1.png')"
							alt=""
						/>
						<div class="box">
							<img
								:src="require('assets/images/download-guide/ph.png')"
								alt=""
							/>
							<span>扫码下载使用</span>
						</div>
					</div>
					<div>
						<img
							:src="require('assets/images/download-guide/and2.png')"
							alt=""
						/>
						<div class="box">
							<img
								:src="require('assets/images/download-guide/and.png')"
								alt=""
							/><span>扫码下载使用</span>
						</div>
					</div> -->
				</div>
			</div>
			<div class="right">
				<img
					style="width: 376.2px"
					:src="require('assets/writtenAi/bgc2.png')"
					alt=""
				/>
			</div>
		</div>

		<div
			class="item_content"
			v-if="tabIndex == 2"
		>
			<div class="left">
				<div class="title">
					<span>APP</span>
					挖潜智库
				</div>

				<div class="text">现已推出APP版本，立即下载体验</div>

				<div class="btnImg1">
					<div>
						<img
							:src="require('assets/writtenAi/code3.png')"
							alt=""
						/>
						<div class="box">
							<img
								:src="require('assets/images/download-guide/ph.png')"
								alt=""
							/>
							<span>扫码下载使用</span>
						</div>
					</div>
					<div>
						<img
							:src="require('assets/writtenAi/code4.png')"
							alt=""
						/>
						<div class="box">
							<img
								:src="require('assets/images/download-guide/and.png')"
								alt=""
							/><span>扫码下载使用</span>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<img
					style="width: 376.2px"
					:src="require('assets/writtenAi/bgc3.png')"
					alt=""
				/>
			</div>
		</div>

		<div
			class="item_content"
			v-if="tabIndex == 3"
		>
			<div class="left">
				<div class="title">
					<span>PC系统</span>
					成语WPS/Office版
				</div>

				<div class="text">现已推出WPS/Office版本，立即下载体验</div>
				<div class="p">操作系统：win7/win8/win10/win11</div>
				<div class="p">Office：MS Word 2007/2010/2013/2016/2019/2021</div>
				<div class="p">WPS：WPS2010/2012/2016/2019</div>
				<div class="p">运行库需求：.Net FrameWork 4.0 和 VSTO 4.0</div>

				<div
					class="btn"
					@click="handleIdiomDown"
				>
					<img
						:src="require('assets/images/download-guide/wps_bai.png')"
						alt=""
					/>
					立即下载
				</div>
			</div>
			<div class="right">
				<img
					style="width: 450px"
					:src="require('assets/images/download-guide/bgc1.png')"
					alt=""
				/>
			</div>
		</div>

		<div class="commonFooter_box">
			<commonFooter />
		</div>
	</div>
</template>

<script>
//例如：import  from '';
import headerComponent from "./headerComponent";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "downloadPage",
  //import引入的组件需要注入到对象中才能使用
  components: {
    // headerComponent,
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      tabIndex: 0,
      tabList: [
        {
          name: "小程序—AI绘画",
          img: require("assets/writtenAi/log2.png"),
          tuijian: require("assets/images/recommend.png"),
        },
        {
          name: "小程序—挖潜智库",
          img: require("assets/writtenAi/log3.png"),
        },
        {
          name: "APP—挖潜智库",
          img: require("assets/writtenAi/log3.png"),
        },
        // {
        //   name: "APP—小语直播",
        //   img: require("assets/writtenAi/log1.png"),
        // },
        // {
        //   name: "PC系统—成语WPS/Office版",
        //   img: require("assets/images/appban.png"),
        // },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleJSDownLoad() {
      // window.open(
      //   "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip"
      // );
      const link = document.createElement("a");
      link.href =
        "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip";
      link.download = "download";
      link.click();
    },

    handleIdiomDown() {
      window.location.href =
        "https://www.writemall.com/download/成语Office-WPS(windows系统).rar";
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log(this.$route.query.index);
    if (this.$route.query.index) {
      this.tabIndex = this.$route.query.index;
    }
  },
};
</script>
<style lang="scss" scoped>
.downloadPage {
  position: absolute;
  background: #f5f5f2;
  top: 0px;
  left: 0;
  width: 100%;

  .banner {
    width: 100%;
    // height: 420px;
  }
  .tab {
    width: 1526px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 0 auto;
    height: 80px;
    border-radius: 5px;
    transform: translateY(-200px);
    .tab_title {
      cursor: pointer;
      // width: 25%;
      flex: 1;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      img {
        width: 48px;
        margin-right: 10px;
      }

      .recommend {
        margin-left: 10px;
        width: 34px;
        height: 15px;
        image-rendering: -webkit-optimize-contrast;
      }
    }

    .isActive {
      color: #ff6808;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 50px;
        height: 2px;
        background-color: #ff6900;
        bottom: -32px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .item_content {
    transform: translateY(-150px);
    width: 1526px;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 135px 0;
    justify-content: space-evenly;

    .left {
      .title {
        font-size: 30px;
        // text-align: center;
        margin-bottom: 46px;
        span {
          color: #ff6900;
        }
      }
      .text {
        color: #999999;
        font-size: 16px;
        font-weight: bold;
      }
      .p {
        color: #999999;
        font-size: 16px;
        margin: 15px 0;
      }
      .btn {
        cursor: pointer;
        margin-top: 39px;
        width: 295px;
        height: 50px;
        background: #ff6900;
        border-radius: 5px;
        color: #fff;
        font-family: PingFang SC;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 34px;
          margin-right: 18px;
        }
      }

      .btnImg {
        width: 182px;
        height: 218px;
        margin: 41px auto 0;
        text-align: center;

        img {
          width: 100%;
          height: 182px;
          margin-bottom: 20px;
        }
      }

      .btnImg1 {
        // width: 459px;
        // height: 203px;
        gap: 10px;
        margin-top: 54px;
        text-align: center;
        font-size: 18px;
        color: #333;
        display: flex;
        justify-content: space-between;
        .box {
          img {
            width: 34px;
            height: 34px;
            vertical-align: middle;
            margin-bottom: 0px;
            margin-right: 9px;
          }
        }
        img {
          width: 163px;
          height: 163px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .commonFooter_box {
    margin-top: 25px;
  }
}
</style>
